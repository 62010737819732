import React, { useContext, Suspense } from 'react';
import { Route, Routes, ScrollToTop } from '@thd-olt-component-react/router';
import { AppContext } from '@thd-nucleus/app-render';
import { Error } from './pages';
import { getRoutes } from './routes';
import './styles/app.scss';

export const App = () => {
  const { instance } = useContext(AppContext);
  const {
    currentRouteName,
    currentRouteComponent
  } = instance.router;
  const routes = getRoutes(currentRouteName, currentRouteComponent);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path={routes.home.path} element={<routes.home.component />} />
        <Route path="/error" element={<Error />} />
        <Route element={<Error />} />
      </Routes>
    </>
  );
};

App.propTypes = {};

App.defaultProps = {};
